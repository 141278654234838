import React from 'react';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <img src="/main_logo.png" alt="Logo" className="footer-logo" />
        <p>LEÓN, GUANAJUATO</p>
        <p>
          <a href="https://wa.me/524771895194" target="_blank" rel="noopener noreferrer" className="whatsapp-link">
            <img src="/whatsapp_icon.png" alt="WhatsApp" className="whatsapp-icon" />
            477 189 5194
          </a>
        </p>
      </div>

      <div className="footer-column">
        <h3>Horarios de Atención</h3>
        <p>Lunes a Viernes: 9:00 AM - 6:00 PM</p>
        <p>Sábado: 10:00 AM - 4:00 PM</p>
        <p>Domingo: Cerrado</p>
      </div>

      <div className="footer-column">
        <h3>Síguenos</h3>
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61558329698298" target="_blank" rel="noopener noreferrer">
            <img src="/facebook_icon.png" alt="Facebook" className="social-icon" />
          </a>
          <a href="https://www.tiktok.com/@soyjaguarsportwear" target="_blank" rel="noopener noreferrer">
            <img src="/tiktok_icon.png" alt="Tik Tok" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/soy_jaguar_sportwaer/" target="_blank" rel="noopener noreferrer">
            <img src="/insta_icon.png" alt="Instagram" className="social-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
