import React, { useState, useEffect } from 'react';
import '../App.css'; 

const Hero = ({ title, subtitle, buttonText }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Define el ancho máximo para considerar como móvil
    };

    // Llama a la función handleResize cuando el componente se monta y en cada cambio de tamaño de la ventana
    handleResize();

    // Agrega un listener para detectar cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpia el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="hero-container" style={{ backgroundImage: `url(${isMobile ? '/mobile_image.png' : '/hero_image.png'})` }}>
      <div className="hero-content">
        {/* Aquí puedes agregar tu contenido como el título, subtítulo y botón */}
      </div>
    </div>
  );
}

export default Hero;
