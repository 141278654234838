import React, { useState, useEffect, useRef } from 'react';
import '../App.css';

const ResenasDetalle = () => {
  const reviews = [
    {
      name: 'Juan Pérez',
      rating: 5,
      comment: 'La calidad es increíble, me encantó la playera Dryfit!',
      profilePic: '/client1.png',
      socialMedia: {
        platform: 'facebook',
        url: 'https://facebook.com/juanperez',
        icon: '/facebook.png',
      },
    },
    {
      name: 'Ana López',
      rating: 4,
      comment: 'El diseño es genial, pero me hubiera gustado más variedad de colores.',
      profilePic: '/client2.png',
      socialMedia: {
        platform: 'twitter',
        url: 'https://twitter.com/analopz',
        icon: '/x.png',
      },
    },
    {
      name: 'Carlos Gómez',
      rating: 5,
      comment: 'Excelente servicio, y la tela es muy resistente. Perfecto para el gimnasio!',
      profilePic: '/client3.png',
      socialMedia: {
        platform: 'instagram',
        url: 'https://instagram.com/carlosgomez',
        icon: '/instagram.png',
      },
    },
    // Puedes añadir más reseñas aquí
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemWidth = 400; // Ancho fijo para cada reseña
  const totalItems = reviews.length * 2; // Duplicamos las reseñas para la transición infinita
  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 5000); // Cambia de reseña cada 5 segundos

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Reseteamos el índice sin transición visible cuando llega al final
    if (currentIndex >= reviews.length) {
      // Breve pausa en la animación para simular la transición infinita
      carouselRef.current.style.transition = 'none';
      setCurrentIndex(0);
    } else {
      // Activamos la transición de nuevo cuando el índice es válido
      carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
    }
  }, [currentIndex, reviews.length]);

  return (
    <div id="resenas-detalle" className="resenas-detalle-section" style={{ overflow: 'hidden' }}>
      <div
        ref={carouselRef}
        className="resenas-carousel"
        style={{
          display: 'flex',
          transform: `translateX(-${(currentIndex % reviews.length) * itemWidth}px)`, // Ciclo continuo
          transition: 'transform 0.5s ease-in-out', // Transición suave
          width: `${totalItems * itemWidth}px`, // Ajuste del ancho total del carrusel
        }}
      >
        {reviews.concat(reviews).map((review, index) => ( // Concatenamos las reseñas para crear efecto infinito
          <div key={index} className="resena-detalle" style={{ width: `${itemWidth}px` }}>
            <div className="profile-info">
              <img src={review.profilePic} alt={review.name} className="profile-pic" />
              <a href={review.socialMedia.url} target="_blank" rel="noopener noreferrer" className="resena-social-icon">
                <img
                  src={review.socialMedia.icon}
                  alt={`${review.socialMedia.platform} icon`}
                  className="resena-social-icon-img"
                />
              </a>
            </div>
            <h4>{review.name}</h4>
            <p>{'★'.repeat(review.rating)}</p>
            <p>{review.comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResenasDetalle;
