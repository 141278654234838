import { useStripe, Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Spinner from './Spinner';

const PaymentForm = ({ cart, datosCliente, datosEnvio }) => {
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const [elements, setElements] = useState(null);
    const [loading, setLoading] = useState(false); // Estado para controlar el spinner

    const navigate = useNavigate();

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await axios.post('https://soy-jaguar.com/api/create-payment-intent/', {
                    cart: cart,
                });
                setClientSecret(response.data.clientSecret);
            } catch (error) {
                console.error('Error al obtener el secret del cliente:', error);
            }
        };

        fetchClientSecret();
    }, [cart]);

    useEffect(() => {
        if (clientSecret && stripe) {
            const appearance = {
                theme: 'stripe',
                variables: {
                    colorPrimary: '#0570de',
                    colorBackground: '#ffffff',
                    colorText: '#30313d',
                    colorDanger: '#df1b41',
                    fontFamily: 'Ideal Sans, system-ui, sans-serif',
                    spacingUnit: '2px',
                    borderRadius: '4px',
                },
            };

            // Aquí se define elements
            const elementsInstance = stripe.elements({ clientSecret, appearance });
            const options = {
                layout: {
                    type: 'tabs',
                    defaultCollapsed: false,
                },
            };

            const paymentElement = elementsInstance.create('payment', options);
            paymentElement.mount("#payment-element");
            setElements(elementsInstance); // Guardar la instancia de elements

            // Limpia el elemento al desmontar
            return () => {
                paymentElement.destroy();
            };
        }
    }, [clientSecret, stripe]);

    // Función para procesar el pago al hacer clic en el botón "Pagar"
    const handlePayment = async (event) => {
        event.preventDefault();

	setLoading(true); // Inicia el spinner

        if (!stripe || !elements) {
            return;
        }


        const { error, paymentIntent } = await stripe.confirmPayment({
            elements, // Usa la instancia guardada de elements
            redirect: 'if_required',
        });

        if (error) {
            console.error('Error de pago:', error);
        } else if (paymentIntent && (paymentIntent.status === 'succeeded' || paymentIntent.status === 'requires_action')) {
	    await handleFinalizarCompra(paymentIntent.id, paymentIntent.status);
	    await sendConfirmationEmail();
	    setLoading(false); // Detiene el spinner	
            navigate('/success', { state: { clientName: datosCliente.nombre, paymentStatus: paymentIntent.status } });
        }
    };

    const handleFinalizarCompra = async (paymentIntentId, paymentStatus)  => {
        const datosCompra = {
            pedido_id: paymentIntentId,
  	    payment_status: paymentStatus,
            cliente: datosCliente,
            envio: datosEnvio,
            cart: cart
        };

        try {
            const response = await axios.post('https://soy-jaguar.com/api/procesar-compra/', datosCompra);
        } catch (error) {
            console.error('Error al finalizar la compra:', error);
            alert('Hubo un error al finalizar la compra. Por favor, inténtalo de nuevo.');
            throw error;
        }
    };

    const sendConfirmationEmail = async () => {
        try {
            await axios.post('https://soy-jaguar.com/api/send-email/', {
                email: datosCliente.email,
		nombre: datosCliente.nombre,
		productos: cart,
		direccion: datosEnvio
            });
	    localStorage.removeItem('cart');
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    };

    return (
        <form onSubmit={handlePayment} style={{ position: 'relative' }}>
            <div id="payment-element">
                {/* Aquí se montará el elemento de pago */}
            </div>
            <button type="submit" disabled={loading} style={{ marginTop: '15px' }}>
                Pagar
            </button>

            {/* Contenedor para el spinner */}
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Fondo semi-transparente
                        zIndex: 100, // Asegúrate de que esté encima de otros elementos
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Spinner /> {/* Componente del spinner */}
                </div>
            )}
        </form>
    );
};

export default PaymentForm;
