import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const ResumenPedido = ({ cart, costoEnvio }) => {
  // Calcular el subtotal sumando los precios totales de todos los productos en el carrito
  const subtotal = cart.reduce((acc, product) => acc + (product.price * product.quantity), 0);

  // Calcular el total sumando el subtotal y el costo de envío
  const total = subtotal + costoEnvio;

  return (
    <div className="resumen-pedido-container">
      <table className="resumen-pedido-table">
        <thead>
          <tr>
            <th>Producto</th>
            <th>Precio</th>
            <th>Cantidad</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {/* Mapear cada producto en el carrito */}
          {cart.map((product, index) => (
            <tr key={index}>
              <td>{product.name}</td>
              <td>${product.price.toFixed(2)}</td>
              <td>{product.quantity}</td>
              <td>${(product.price * product.quantity).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="resumen-pedido-summary">
        {/* Mostrar subtotal, costo de envío y total */}
        <p><strong>Subtotal:</strong> ${subtotal.toFixed(2)}</p>
        <p><strong>Costo de Envío:</strong> ${costoEnvio.toFixed(2)}</p>
        <p><strong>Total:</strong> ${total.toFixed(2)}</p>
      </div>
      {/* Agrega un enlace al componente FinalizarCompra */}
      <div className="button-container">
        <Link to="/finalizar-compra">
          <button className="resumen-pedido-button">Finalizar Compra</button>
        </Link>
      </div>

      <p className="whatsapp-assistance">
        <a 
          href="https://wa.me/524771895194?text=Hola,%20necesito%20ayuda%20con%20un%20pedido." 
          target="_blank" 
          rel="noopener noreferrer" 
          className="whatsapp-link-resumen"
        >
          <img src="/whatsapp.png" alt="WhatsApp" className="whatsapp-icon-resumen" />
          Solicitar asistencia por Whatsapp
        </a>
      </p>

      <img src="/resumen_pedido_image.png" alt="Descripción de la imagen para móviles" className="resumen-pedido-image" />
    </div>
  );
};

export default ResumenPedido;
