// src/components/Reseñas.js
import React from 'react';
import '../App.css';

const Resenas = () => {
  const reviews = [
    {
      name: 'Juan Pérez',
      rating: 5,
      comment: 'La calidad es increíble, me encantó la playera Dryfit!',
      profilePic: '/client1.png', // Ruta de la imagen de perfil en public
      socialMedia: {
        platform: 'facebook',
        url: 'https://facebook.com/juanperez',
        icon: '/facebook.png' // Ruta del ícono de Facebook en public
      }
    },
    {
      name: 'Ana López',
      rating: 4,
      comment: 'El diseño es genial, pero me hubiera gustado más variedad de colores.',
      profilePic: '/client2.png', // Ruta de la imagen de perfil en public
      socialMedia: {
        platform: 'twitter',
        url: 'https://twitter.com/analopz',
        icon: '/x.png' // Ruta del ícono de Twitter en public
      }
    },
    {
      name: 'Carlos Gómez',
      rating: 5,
      comment: 'Excelente servicio, y la tela es muy resistente. Perfecto para el gimnasio!',
      profilePic: '/client3.png', // Ruta de la imagen de perfil en public
      socialMedia: {
        platform: 'instagram',
        url: 'https://instagram.com/carlosgomez',
        icon: '/instagram.png' // Ruta del ícono de Instagram en public
      }
    },
    {
      name: 'Juan Pérez',
      rating: 5,
      comment: 'La calidad es increíble, me encantó la playera Dryfit!',
      profilePic: '/client1.png', // Ruta de la imagen de perfil en public
      socialMedia: {
        platform: 'facebook',
        url: 'https://facebook.com/juanperez',
        icon: '/facebook.png' // Ruta del ícono de Facebook en public
      }
    },
    {
      name: 'Ana López',
      rating: 4,
      comment: 'El diseño es genial, pero me hubiera gustado más variedad de colores.',
      profilePic: '/client2.png', // Ruta de la imagen de perfil en public
      socialMedia: {
        platform: 'twitter',
        url: 'https://twitter.com/analopz',
        icon: '/x.png' // Ruta del ícono de Twitter en public
      }
    },
    {
      name: 'Carlos Gómez',
      rating: 5,
      comment: 'Excelente servicio, y la tela es muy resistente. Perfecto para el gimnasio!',
      profilePic: '/client3.png', // Ruta de la imagen de perfil en public
      socialMedia: {
        platform: 'instagram',
        url: 'https://instagram.com/carlosgomez',
        icon: '/instagram.png' // Ruta del ícono de Instagram en public
      }
    },
  ];

  return (
    <div id="resenas" className="resenas-section">
      <h2>Lo que opinan los demás ❤︎</h2>
      <div className="resenas-container">
        {reviews.map((review, index) => (
          <div key={index} className="resena">
            <div className="profile-info">
              <img
                src={review.profilePic}
                alt={review.name}
                className="profile-pic"
              />
              <a href={review.socialMedia.url} target="_blank" rel="noopener noreferrer" className="resena-social-icon">
                <img
                  src={review.socialMedia.icon}
                  alt={`${review.socialMedia.platform} icon`}
                  className="resena-social-icon-img"
                />
              </a>
            </div>
            <h4>{review.name}</h4>
            <p>{'★'.repeat(review.rating)}</p>
            <p>{review.comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resenas;
