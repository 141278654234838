import React from 'react';

const Carrito = ({ cart }) => {
  // Calcula el total del carrito
  const total = cart.reduce((accumulator, item) => accumulator + (item.price * (item.quantity || 1)), 0);

  return (
    <div className="carrito">
      <a href="/pagina-del-carrito"> {/* Enlace a la página del carrito */}
        <img src="/shopping.png" alt="Carrito de compras" className="carrito-icon" />
        <span className="carrito-total"> ${total.toFixed(2)}</span>
      </a>
    </div>
  );
};

export default Carrito;
