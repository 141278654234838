// ProductGrid.js
import React from 'react';
import ProductCard from './ProductCard';
import '../App.css';

const ProductGrid = ({ products, addToCart }) => {
  return (
    <div className="product-grid">
      {products.map((product, index) => (
        <div key={index} className="product-grid-item">
          <ProductCard
            name={product.name}
            price={product.price}
            sizes={product.sizes}
            colors={product.colors}
            defaultImage={product.defaultImage}
            secondaryImage={product.secondaryImage}
            addToCart={addToCart}
          />
        </div>	
      ))}
    </div>
  );
};

export default ProductGrid;

