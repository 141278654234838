import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PaymentForm from './PaymentForm';
import FormularioCliente from './FormularioCliente';
import FormularioEnvio from './FormularioEnvio';
import ResumenPedidoConImagen from './ResumenPedidoConImagen';
import Spinner from './Spinner'; // Importa el componente Spinner
import '../App.css';

const FinalizarCompra = () => {
    const [cart, setCart] = useState([]);
    const [datosCliente, setDatosCliente] = useState(null);
    const [datosEnvio, setDatosEnvio] = useState(null);
    const [isPayment, setIsPayment] = useState(false);
    const [formulariosVisible, setFormulariosVisible] = useState(true);
    const [proceedClicked, setProceedClicked] = useState(false);
    const [errorCliente, setErrorCliente] = useState(null);
    const [errorEnvio, setErrorEnvio] = useState(null);
    const [loading, setLoading] = useState(false); // Estado para controlar el spinner

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        if (storedCart) {
            setCart(storedCart);
        }
    }, []);

    const handleClienteSubmit = (datosCliente) => {
        setDatosCliente(datosCliente);
    };

    const handleEnvioSubmit = (datosEnvio) => {
        setDatosEnvio(datosEnvio);
    };

    const handleProceedToPayment = async () => {
        setProceedClicked(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const camposFaltantes = validateFields();

        if (camposFaltantes.length > 0) {
            // Establece los errores según los campos que faltan
            setErrorCliente(camposFaltantes.filter(campo => campo.includes("cliente")).join(', '));
            setErrorEnvio(camposFaltantes.filter(campo => campo.includes("envío")).join(', '));
            return; // No proceder si hay campos faltantes
        }

        setLoading(true); // Inicia el spinner

        try {
            setTimeout(() => {
                setFormulariosVisible(false);
                setIsPayment(true);
                setLoading(false); // Detiene el spinner
            }, 500); // Tiempo de la transición
        } catch (error) {
            console.error('Error al finalizar la compra:', error);
            alert('Hubo un error al finalizar la compra. Por favor, inténtalo de nuevo.');
            setLoading(false); // Detiene el spinner en caso de error
        }
    };

    const validateFields = () => {
        const camposFaltantes = [];
        const clienteFields = ['nombre', 'apellido', 'email', 'telefono']; // Campos requeridos del cliente
        const envioFields = ['ciudad', 'estado', 'direccion', 'codigoPostal']; // Campos requeridos de envío

        // Validar campos del cliente
        if (!datosCliente) {
            camposFaltantes.push("Faltan los datos del cliente.");
        } else {
            clienteFields.forEach(field => {
                if (!datosCliente[field]) {
                    camposFaltantes.push(`*Falta el ${field} del cliente.`);
                }
            });
        }

        // Validar campos de envío
        if (!datosEnvio) {
            camposFaltantes.push("Faltan los datos de envío.");
        } else {
            envioFields.forEach(field => {
                if (!datosEnvio[field]) {
                    camposFaltantes.push(`*Falta la ${field} de envío.`);
                }
            });
        }

        return camposFaltantes;
    };

    return (
        <div className="finalizar-compra-page">
            <div className="finalizar-compra-container">
                {loading ? (
                    <Spinner /> // Muestra el spinner mientras se carga el siguiente componente
                ) : (
                    <>
                        {formulariosVisible && (
                            <div className="formularios-container">
                                <div className={`formulario ${proceedClicked ? 'move-left' : ''}`}>
                                    <FormularioCliente onSubmit={handleClienteSubmit} error={errorCliente} />
                                </div>
                                <div className={`formulario ${proceedClicked ? 'move-left' : ''}`}>
                                    <FormularioEnvio onSubmit={handleEnvioSubmit} error={errorEnvio} />
                                </div>
                                <button onClick={handleProceedToPayment}>Proceder al pago</button>
                            </div>
                        )}

                        {isPayment && (
                            <div className="payment-form animate-slide-up show">
                                <PaymentForm cart={cart} datosCliente={datosCliente} datosEnvio={datosEnvio} />
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className="resumen-con-imagen-container">
                <ResumenPedidoConImagen cart={cart} costoEnvio={99} />
            </div>
        </div>
    );
};

export default FinalizarCompra;
