import React from 'react';
import '../App.css'; 

// Componente de contenedor de imagen
class ImageWrapper extends React.Component {
  render() {
    return (
      <div className="image-wrapper">
        {/* La imagen para dispositivos de escritorio */}
        <img src="/banner_image_desktop.png" alt="Descripción de la imagen para escritorio" className="desktop-image" />
        {/* La imagen para dispositivos móviles */}
        <img src="/banner_image_mobile.png" alt="Descripción de la imagen para móviles" className="mobile-image" />
	<div className="info-banner-separator"></div>
      </div>
    );
  }
}

export default ImageWrapper;