import React from 'react';
import ReactDOM from 'react-dom/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const stripePromise = loadStripe('pk_live_51Le6sKJNdwwuU7iRos7mN6COrg6ePuHsn8JVIOTnysqSwQY0nGJQ9vU6LesQnVJlTWXiPxDd8vXRQP8bb1f1bo9d00Xq9vMmjr');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);

// Si quieres medir el rendimiento de tu aplicación, puedes usar reportWebVitals
reportWebVitals();
