import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const ProductCard = ({ name, price, sizes, colors, defaultImage, secondaryImage, addToCart }) => {
  const [image, setImage] = useState(defaultImage);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [bubblePosition, setBubblePosition] = useState({ x: 0, y: 0 });

  // Nuevo: manejar el clic en la imagen
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setImage(secondaryImage);
  };

  const handleMouseLeave = () => {
    setImage(defaultImage);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size === selectedSize ? null : size);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color === selectedColor ? null : color);
  };

  const handleAddToCart = (event) => {
    if (selectedSize && selectedColor) {
      const productToAdd = {
        name,
        price: parseFloat(price[0].replace('$', '')),
        discountedPrice : price.length > 1 ? parseFloat(price[1].replace('$', '')) : null,
        image: defaultImage,
        size: selectedSize,
        color: selectedColor,
        quantity: 1,
      };

      addToCart(productToAdd);
      setSelectedSize(null);
      setSelectedColor(null);

      // Obtener la posición del botón
      const buttonRect = event.target.getBoundingClientRect();
      const buttonX = buttonRect.left + window.pageXOffset;
      const buttonY = buttonRect.top + window.pageYOffset;

      // Establecer las coordenadas de la burbuja
      setBubblePosition({ x: buttonX, y: buttonY });

      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } else {
      // Obtener la posición del botón
      const buttonRect = event.target.getBoundingClientRect();
      const buttonX = buttonRect.left + window.pageXOffset;
      const buttonY = buttonRect.top + window.pageYOffset;

      // Establecer las coordenadas de la burbuja
      setBubblePosition({ x: buttonX, y: buttonY });
      setShowAlert(true); // Mostrar la burbuja de alerta
      setTimeout(() => setShowAlert(false), 3000);
    }
  };

  // Nuevo: manejar el clic en la imagen
  const handleImageClick = () => {
    navigate(`/producto/${name}`);
    window.scrollTo(0, 0);
  };

  const allSizes = ['XS', 'S', 'M', 'L', 'XL'];

  return (
    <div className="product-card">
        <div className="image-container">
          <img 
            src={image} 
            alt={name} 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
            onClick={handleImageClick} // Navegación al hacer clic en la imagen
            style={{ cursor: 'pointer' }}
          />
          <div className="price-banner">
           <p>
            {price[0]} MXN
            {price.length > 1 && (
              <span style={{ color: "#8ab000", textDecoration: "line-through", marginLeft: "5px" }}>
                {price[1]}
              </span>
            )}
           </p>
          </div>
        </div>
        <div className="product-details">
          <h2>{name}</h2>
        </div>

        <div style={{ fontWeight: "bold" }}>Selecciona</div>


      <div className="size-banner">
        Tallas: {allSizes.map((size) => (
            <span
              key={size}
              onClick={() => handleSizeSelect(size)}
              className={sizes.includes(size) ? `selectable ${size === selectedSize ? 'selected' : ''}` : ""}
              style={{
                textDecoration: sizes.includes(size) ? "none" : "line-through",
                background: sizes.includes(size)
                  ? "none"
                  : "linear-gradient(-45deg, #f00 25%, transparent 25%, transparent 75%, #f00 75%, #f00)",
                WebkitBackgroundClip: "text",
                color: sizes.includes(size) ? "#000" : "#90A6AD",
                marginRight: "5px"
              }}
            >
              {size}
            </span>
        ))}
      </div>

      <div className="color-container">
        Colores: {colors.map((color, index) => (
          <span
            key={index}
            onClick={() => handleColorSelect(color)}
            className={`selectable ${color === selectedColor ? 'selected' : ''}`}
            style={{
              backgroundColor: color,
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              display: "inline-block",
              margin: "0 5px"
            }}
          ></span>
        ))}
      </div>
        

        <button onClick={handleAddToCart}>Agregar al carrito</button>
        {showSuccess && (
          <div className={`success-bubble ${showSuccess ? 'show' : ''}`} style={{ top: bubblePosition.y, left: bubblePosition.x+8 }}>
            Agregado con éxito
          </div>
        )}

      {/* Burbuja de alerta si no se selecciona talla o color */}
      {showAlert && (
            <div
              className={`alert-bubble ${showAlert ? 'show' : 'hide'}`}
              style={{ top: bubblePosition.y, left: bubblePosition.x - 40 }}
            >
              🤭 ¡Ups! - Selecciona Talla y Color
            </div>
          )}
      </div>

  );
};

export default ProductCard;
