import React from 'react';
import '../App.css'; 

// Datos de ejemplo para los íconos y el texto
const items = [
  { icon: '/1.png', text: 'Materiales que permiten una amplia gama de movimiento.' },
  { icon: '/2.png', text: 'Resistencia a los estiramientos y al desgaste por uso frecuente.' },
  { icon: '/3.png', text: 'Materiales livianos para evitar pesadez y facilitar el movimiento.' },
  { icon: '/4.png', text: 'Material que previene el crecimiento y propagación de bacterias.' }
];

const InfoBanner = () => {
  return (
    <>
      <div className="info-banner">
        <div className="info-banner-main-container">
          <span className="info-banner-main-text">Probada para resistir, diseñada para durar</span>
        </div>
        <h2 style={{ fontWeight: 'bold', fontSize: '40px', color: 'white' }}>
          TECNOLOGÍA
        </h2>
        <div className="info-banner-items">
          {items.map((item, index) => (
            <div key={index} className="info-banner-item">
              <img src={item.icon} alt={`Icon ${index}`} className="info-banner-icon" />
              <span className="info-banner-item-text">{item.text}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="info-banner-separator"></div>
    </>
  );
};

export default InfoBanner;
