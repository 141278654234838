import React, { useState, useEffect } from 'react';

const FormularioEnvio = ({ onSubmit, error }) => {
  const [envio, setEnvio] = useState({
    ciudad: '',
    estado: '',
    direccion: '',
    codigoPostal: ''
  });
  const [errorFields, setErrorFields] = useState({});

  // Maneja el cambio en los campos del formulario y actualiza el estado en el componente
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnvio(prevEnvio => ({
      ...prevEnvio,
      [name]: value
    }));

    // Limpia el error específico del campo cuando el usuario comienza a escribir
    setErrorFields(prevError => ({
      ...prevError,
      [name]: ''
    }));
  };

  // Propaga los cambios al componente padre (FinalizarCompra) usando useEffect
  useEffect(() => {
    onSubmit(envio);
  }, [envio, onSubmit]);

  // Mostrar errores específicos si hay alguno
  useEffect(() => {
    if (error) {
      const errorMessages = error.split(', ');
      const updatedErrors = {};
      errorMessages.forEach(err => {
        const field = err.toLowerCase().includes('ciudad') ? 'ciudad' :
                      err.toLowerCase().includes('estado') ? 'estado' :
                      err.toLowerCase().includes('direccion') ? 'direccion' :
                      err.toLowerCase().includes('codigopostal') ? 'codigoPostal' :
                      null;
        if (field) {
          updatedErrors[field] = err; // Establece el mensaje de error para el campo correspondiente
        }
      });
      setErrorFields(updatedErrors);
    }
  }, [error]);

  return (
    <form>
      <h2>¿A dónde te enviamos, galán?</h2>
      
      <label htmlFor="ciudad">Ciudad:</label>
      <input
        type="text"
        id="ciudad"
        name="ciudad"
        value={envio.ciudad}
        onChange={handleChange}
        required
      />
      {errorFields.ciudad && <p className="error-message">{errorFields.ciudad}</p>}

      <label htmlFor="estado">Estado:</label>
      <input
        type="text"
        id="estado"
        name="estado"
        value={envio.estado}
        onChange={handleChange}
        required
      />
      {errorFields.estado && <p className="error-message">{errorFields.estado}</p>}

      <label htmlFor="direccion">Dirección:</label>
      <input
        type="text"
        id="direccion"
        name="direccion"
        value={envio.direccion}
        onChange={handleChange}
        required
      />
      {errorFields.direccion && <p className="error-message">{errorFields.direccion}</p>}

      <label htmlFor="codigoPostal">Código Postal:</label>
      <input
        type="text"
        id="codigoPostal"
        name="codigoPostal"
        value={envio.codigoPostal}
        onChange={handleChange}
        required
      />
      {errorFields.codigoPostal && <p className="error-message">{errorFields.codigoPostal}</p>}
    </form>
  );
};

export default FormularioEnvio;
