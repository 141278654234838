import React from 'react';

const ResumenPedidoConImagen = ({ cart, costoEnvio }) => {
  // Calcular el subtotal sumando los precios totales de todos los productos en el carrito
  const subtotal = cart.reduce((acc, product) => acc + (product.price * product.quantity), 0);

  // Calcular el total sumando el subtotal y el costo de envío
  const total = subtotal + costoEnvio;

  return (
    <div className="productos-container">
      <h2>Resumen de tu Pedido, Jaguar</h2>
      <div>
        {/* Mapear cada producto en el carrito */}
        {cart.map((product, index) => (
          <div key={index} className="producto">
            <img src={product.image} alt={product.name} className="producto-imagen" />
            <p>{product.name} → ${product.price} x {product.quantity} = ${product.price * product.quantity}</p>
          </div>
        ))}
        {/* Mostrar subtotal, costo de envío y total */}
        <p><span className="label">Subtotal:</span> ${subtotal.toFixed(2)}</p>
        <p><span className="label">Costo de Envío:</span> ${costoEnvio.toFixed(2)}</p>
        <p><span className="label">Total:</span> ${total.toFixed(2)}</p>
      </div>

      <p className="whatsapp-assistance">
        <a 
          href="https://wa.me/524771895194?text=Hola,%20necesito%20ayuda%20con%20un%20pedido." 
          target="_blank" 
          rel="noopener noreferrer" 
          className="whatsapp-link-resumen"
        >
          <img src="/whatsapp.png" alt="WhatsApp" className="whatsapp-icon-resumen" />
          Solicitar asistencia por Whatsapp
        </a>
      </p>

    </div>
  );
};

export default ResumenPedidoConImagen;
