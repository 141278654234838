import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Carrito from './Carrito';

const Header = ({ cart }) => { // Recibe el carrito como prop
    const [isActive, setIsActive] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        } else {
            const url = `/#${sectionId}`;
            window.location.href = url;
        }
    };

    // Condicional para aplicar fondo negro en todas las páginas excepto en Home ('/')
    const navbarStyle = location.pathname === '/'
        ? { // Estilo para la página de inicio (sin fondo negro)
            position: 'absolute',
            top: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '50px 0px 20px',
            zIndex: 1000,
        }
        : { // Estilo para otras páginas (fondo negro)
            position: 'absolute',
            top: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '50px 0px 20px',
            zIndex: 1000,
            backgroundColor: 'black',
        };

    return (
        <div>
            <div className="free-shipping-banner">
                <span className="free-shipping-text ">ENVÍOS GRATIS A PARTIR DE 999 MXN</span>
                <Carrito cart={cart} />
            </div>
            <header>
                <nav className="navbar" style={navbarStyle}>
                    <div className="logo">
                        <a href="/">
                            <img src="/main_logo.png" alt="machine learning en espanol" />
                        </a>
                    </div>
                    <div className={`hamburger ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <ul className={`nav-links ${isActive ? 'active' : ''}`}>
                        <li><a href="/">Tienda</a></li>
                        <li><a href="/pagina-del-carrito">Carrito</a></li>
			{/* <li><a href="/blog-machine-learning-espanol">Blog</a></li> */}
                        <li><a href="#resenas" onClick={(e) => { e.preventDefault(); scrollToSection('resenas'); }}>Reseñas</a></li>
                    </ul>
                </nav>
            </header>
        </div>
    );
};

export default Header;
