import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

const PaymentSuccess = ({ clearCart }) => {
    const location = useLocation();
    const clientName = location.state?.clientName || 'Cliente';
    const paymentStatus = location.state?.paymentStatus || 'unknown';

    useEffect(() => {
            clearCart(); // Limpiar el carrito
    }, [clearCart]);


    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
        },
        heading: {
            fontSize: '3rem',
            color: '#ccfe06',
            backgroundColor: 'black',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
        paragraph: {
            fontSize: '1.5rem',
            color: '#333',
        }
    };

let message;
let heading;

if (paymentStatus === 'succeeded') {
    message = (
        <>
            {`Gracias por tu compra, ${clientName}` }
            {' • Tu pago fue exitoso'}
            {' • Ya estamos trabajando en tu pedido'}
            <br />
            <br />
            {'Revisa tu Correo con las Instrucciones'}
        </>
    );
    heading = 'Pago Exitoso';
} else if (paymentStatus === 'requires_action') {
    message = (
        <>
            {`Gracias por tu compra, ${clientName} ` }
            {' • Haz tu pago en OXXO  '}
	    {' • Te enviaremos a la brevedad' }
            <br />
	    <br />
            {'Revisa tu Correo con las Instrucciones'}
        </>
    );
    heading = 'Esperamos tu pago';
} else {
    message = `Gracias por tu compra, ${clientName}. El estado de tu pago es desconocido.`;
    heading = 'Estado de Pago Desconocido';
}

return (
    <div style={styles.container}>
        <h1 style={styles.heading}>{heading}</h1>
        <p style={styles.paragraph}>{message}</p>
    </div>
);

};

export default PaymentSuccess;
