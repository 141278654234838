import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ResenasDetalle from './ResenasDetalle';

const ProductoDetalle = ({ products, addToCart }) => {
  const { productName } = useParams(); // Obtener el nombre del producto desde la URL
  const product = products.find(p => p.name === productName); // Buscar el producto por nombre

  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Imagen principal
  const [autoPlay, setAutoPlay] = useState(true); // Estado de reproducción automática
  const [selectedSize, setSelectedSize] = useState(null); // Talla seleccionada
  const [selectedColor, setSelectedColor] = useState(null); // Color seleccionado
  const [showSuccess, setShowSuccess] = useState(false); // Mostrar mensaje de éxito
  const [showAlert, setShowAlert] = useState(false);
  const [bubblePosition, setBubblePosition] = useState({ x: 0, y: 0 }); // Posición de la burbuja
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (autoPlay) {
      interval = setInterval(() => {
        setSelectedImageIndex(prevIndex =>
          prevIndex === product.detail_images.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000); // Cambia cada 3 segundos
    }

    return () => {
      clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonta
    };
  }, [autoPlay, product.detail_images.length]);

  if (!product) {
    return <div>Producto no encontrado.</div>;
  }

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index); // Cambia la imagen principal cuando se hace clic en una miniatura
    setAutoPlay(false); // Detener la reproducción automática cuando se selecciona manualmente
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size === selectedSize ? null : size);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color === selectedColor ? null : color);
  };

const handleAddToCart = (event, navigateToCart = false) => {
  if (selectedSize && selectedColor) {
    const productToAdd = {
      name: product.name,
      price: parseFloat(product.price[0].replace('$', '')),
      discountedPrice : product.price.length > 1 ? parseFloat(product.price[1].replace('$', '')) : null,
      image: product.detail_images[0],
      size: selectedSize,
      color: selectedColor,
      quantity: 1,
    };

    addToCart(productToAdd);
    setSelectedSize(null);
    setSelectedColor(null);

    // Obtener la posición del botón
    const buttonRect = event.target.getBoundingClientRect();
    const buttonX = buttonRect.left + window.pageXOffset;
    const buttonY = buttonRect.top + window.pageYOffset;

    // Establecer las coordenadas de la burbuja
    setBubblePosition({ x: buttonX, y: buttonY });

    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);

    if (navigateToCart) {
      // Redirigir a la página del carrito
      navigate("/pagina-del-carrito");
    }
  } else {
    // Obtener la posición del botón
    const buttonRect = event.target.getBoundingClientRect();
    const buttonX = buttonRect.left + window.pageXOffset;
    const buttonY = buttonRect.top + window.pageYOffset;

    // Establecer las coordenadas de la burbuja
    setBubblePosition({ x: buttonX, y: buttonY });
    setShowAlert(true); // Mostrar la burbuja de alerta
    setTimeout(() => setShowAlert(false), 3000);
  }
};


  const handleImageClick = () => {
    navigate(`/producto/${product.name}`);
    window.scrollTo(0, 0);
  };

  // Filtrar productos relacionados excluyendo el producto actual
  const relatedProducts = products
    .filter(p => p.name !== productName)
    .slice(0, 6); // Obtener los primeros 6 productos relacionados


const handleMouseMove = (e) => {
  const image = e.currentTarget; // Obtener la imagen principal directamente
  const rect = image.getBoundingClientRect();
  const x = e.clientX - rect.left; // Obtener la posición X del mouse dentro de la imagen
  const y = e.clientY - rect.top; // Obtener la posición Y del mouse dentro de la imagen

  // Ajustar el origen del zoom según la posición del mouse
  image.style.transformOrigin = `${(x / rect.width) * 100}% ${(y / rect.height) * 100}%`;
  image.style.transform = 'scale(4)'; // Solo aplicar el zoom a la imagen
};

return (
  <div className="detail-page">
    <div className="product-detail-container">
      <div className="product-detail-columns">
        <div className="product-images">
          <div
            className="main-image-container"
          >
            <img
              src={product.detail_images[selectedImageIndex]}
              alt={`${product.name} detalle ${selectedImageIndex}`}
              className="main-image"
              onMouseMove={handleMouseMove}
              style={{ transition: 'transform 0.3s ease', width: '100%', height: 'auto' }} // Añadir transición
           onMouseLeave={() => {
              const image = document.querySelector('.main-image');
              image.style.transform = 'scale(1)'; // Restablecer el zoom al salir
            }}
            />
          </div>

          {/* Miniaturas */}
          <div className="thumbnail-container">
            {product.detail_images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${product.name} miniatura ${index}`}
                onClick={() => handleThumbnailClick(index)}
                style={{
                  width: '50px',
                  height: 'auto',
                  border: selectedImageIndex === index ? '2px solid #000' : '1px solid #ccc',
                  cursor: 'pointer',
                }}
              />
            ))}
          </div>
        </div>

        {/* *************************************			Columna izquierda: información del producto */}
        <div className="product-info">
          <h1>{product.name}</h1>
	  <p>🔥 ¡Lleva tu entrenamiento al siguiente nivel con el short WDFK Corto! 🔥</p>

          {/* Información del precio del producto */}
          <div className="price-banner-detail">
            <p>
              {product.price[0]} MXN
              {product.price.length > 1 && (
                <span
                  style={{
                    color: "#8ab000",
                    textDecoration: "line-through",
                    marginLeft: "5px",
                  }}
                >
                  {product.price[1]}
                </span>
              )}
            </p>
          </div>

          <h2>Selecciona Talla y Color</h2>

          {/* Selección de tallas */}
          <div className="size-banner">
            {product.sizes.map((size) => (
              <span
                key={size}
                onClick={() => handleSizeSelect(size)}
                className={`selectable ${
                  size === selectedSize ? 'selected' : ''
                }`}
                style={{
                  marginRight: '10px',
                  padding: '5px',
                  border: selectedSize === size ? '2px solid #000' : '',
                  cursor: 'pointer',
                }}
              >
                {size}
              </span>
            ))}
          </div>

          {/* Selección de colores */}
          <div className="color-container">
            {product.colors.map((color, index) => (
              <span
                key={index}
                onClick={() => handleColorSelect(color)}
                className={`selectable ${
                  color === selectedColor ? 'selected' : ''
                }`}
                style={{
                  backgroundColor: color,
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  margin: '0 5px',
                  cursor: 'pointer',
                  border: selectedColor === color ? '2px solid #000' : '1px solid #ccc',
                }}
              ></span>
            ))}
          </div>

          {/* Botón de agregar al carrito */}
          <button onClick={handleAddToCart}>Agregar al carrito</button>

          {/* Botón de agregar, y luego ir al carrito */}
          <button onClick={(event) => handleAddToCart(event, true)}>Comprar ahora</button>

          {/* Mensaje de éxito */}
          {showSuccess && (
            <div
              className={`success-bubble ${showSuccess ? 'show' : ''}`}
              style={{ top: bubblePosition.y, left: bubblePosition.x + 4 }}
            >
              Agregado con éxito
            </div>
          )}

      {/* Burbuja de alerta si no se selecciona talla o color */}
      {showAlert && (
            <div
              className={`alert-bubble ${showAlert ? 'show' : 'hide'}`}
              style={{ top: bubblePosition.y, left: bubblePosition.x - 40 }}
            >
              🤭 ¡Ups! - Selecciona Talla y Color
            </div>
          )}

        </div>
      </div>


      <ResenasDetalle />

      {/* Información adicional */}
      <div className="additional-info-container">
  <h2>🏋️‍♂️ Short WDFK: Desata tu Potencial</h2>
  <p>
    El nuevo short WDFK ha sido diseñado para acompañarte en cada movimiento, desde el gimnasio hasta la playa. Su innovadora tela elástica de 4 direcciones te brinda la máxima flexibilidad y libertad, permitiéndote entrenar sin restricciones y alcanzar tu mejor rendimiento en cada sesión de CrossFit o cualquier deporte de alta intensidad.
  </p>

  <h2>🌊 Diseño para Rendimiento y Estilo</h2>
  <p>
    Ya sea que estés sudando en el box o relajándote en la playa, el short WDFK se adapta a tu estilo de vida activo. Secado rápido y con bolsillos profundos, es perfecto para esos días que comienzan con un entrenamiento y terminan con un chapuzón en el agua. La funcionalidad nunca había sido tan elegante.
  </p>

  <h2>💪 Comodidad y Soporte en Cada Movimiento</h2>
  <p>
    El resorte forrado en licra de alta flexibilidad ofrece el soporte que necesitas para mantenerte cómodo y seguro durante cada entrenamiento. Además, las aperturas laterales en el diseño te proporcionan una mayor libertad de movimiento, ya sea para saltos explosivos, levantamientos o carreras intensas.
  </p>

  <h3>Características principales:</h3>
  <ul>
    <li>Tela elástica en 4 direcciones para máxima movilidad.</li>
    <li>Secado rápido, ideal para playa, piscina y entrenamientos intensos.</li>
    <li>Bolsillos profundos para mayor practicidad durante tus actividades.</li>
    <li>Resorte forrado en licra para mayor soporte y comodidad.</li>
    <li>Diseño con aperturas laterales para mayor libertad de movimiento.</li>
  </ul>

  <p>
    Con WDFK, no solo estás eligiendo un short, estás eligiendo superar tus límites y estar listo para cualquier desafío. 💥
  </p>
      </div>

      {/* Sección de productos relacionados */}
      <div className="related-products">
        <h2>Productos Relacionados</h2>
        <div className="related-products-container">
          {relatedProducts.map((relatedProduct, index) => (
            <div key={index} className="related-product">
              <img
                src={relatedProduct.detail_images[0]}
                alt={relatedProduct.name}
                className="related-product-image"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll suave al inicio
            navigate(`/producto/${relatedProduct.name}`);
          }}
              />
              <div className="related-product-info">
                <h3>{relatedProduct.name}</h3>
                <p>{relatedProduct.price[0]} MXN</p>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  </div>
);
};

export default ProductoDetalle;
