import React, { useState, useEffect } from 'react';

const FormularioCliente = ({ onSubmit, error }) => {
    const [cliente, setCliente] = useState({
        nombre: '',
        apellido: '',
        email: '',
        telefono: ''
    });
    const [errorFields, setErrorFields] = useState({});

    // Maneja el cambio en los campos del formulario y actualiza el estado en el componente
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCliente(prevCliente => ({
            ...prevCliente,
            [name]: value
        }));

        // Limpia el error específico del campo cuando el usuario comienza a escribir
        setErrorFields(prevError => ({
            ...prevError,
            [name]: ''
        }));
    };

    // Propaga los cambios al componente padre (FinalizarCompra) usando useEffect
    useEffect(() => {
        onSubmit(cliente);
    }, [cliente, onSubmit]);

    // Mostrar errores específicos si hay alguno
    useEffect(() => {
        if (error) {
            const errorMessages = error.split(', ');
            const updatedErrors = {};
            errorMessages.forEach(err => {
                const field = err.toLowerCase().includes('nombre') ? 'nombre' :
                              err.toLowerCase().includes('apellido') ? 'apellido' :
                              err.toLowerCase().includes('email') ? 'email' :
                              err.toLowerCase().includes('telefono') ? 'telefono' :
                              null;
                if (field) {
                    updatedErrors[field] = err; // Establece el mensaje de error para el campo correspondiente
                }
            });
            setErrorFields(updatedErrors);
        }
    }, [error]);

    return (
        <form>
            <h2>Jaguar, comparte tus datos:</h2>
            <label htmlFor="nombre">Nombre:</label>
            <input
                type="text"
                id="nombre"
                name="nombre"
                value={cliente.nombre}
                onChange={handleChange}
                required
            />
            {errorFields.nombre && <div className="error-message">{errorFields.nombre}</div>}

            <label htmlFor="apellido">Apellido:</label>
            <input
                type="text"
                id="apellido"
                name="apellido"
                value={cliente.apellido}
                onChange={handleChange}
                required
            />
            {errorFields.apellido && <div className="error-message">{errorFields.apellido}</div>}

            <label htmlFor="email">Email:</label>
            <input
                type="email"
                id="email"
                name="email"
                value={cliente.email}
                onChange={handleChange}
                required
            />
            {errorFields.email && <div className="error-message">{errorFields.email}</div>}

            <label htmlFor="telefono">Teléfono:</label>
            <input
                type="tel"
                id="telefono"
                name="telefono"
                value={cliente.telefono}
                onChange={handleChange}
                required
            />
            {errorFields.telefono && <div className="error-message">{errorFields.telefono}</div>}
        </form>
    );
};

export default FormularioCliente;
